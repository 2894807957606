import React from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export const KingsNFTFund = () => {
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
      <input
        autoFocus
        id="search"
        type="text"
        placeholder="Filter By Name"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
      <button type="button" className="btn btn-danger" onClick={onClear}>
        X
      </button>
    </>
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  function filterTokens(input) {
    return input.name.toLowerCase().includes(filterText.toLowerCase());
  }

  return (
    <div>
      <LoadROIdataTable
        //tokenData={tokenData}
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        filterFunction={filterTokens}
      />
    </div>
  );
};

export const LoadROIdataTable = (props) => {
  function StakingModalBlurb(props) {
    const blurbHtml = props.row.stakingBlurbHtml;
    if (blurbHtml) {
      return <>{blurbHtml}</>;
    }
    return "";
  }

  const {
    data: birdeyeSolPriceData,
    isLoading: birdeyeSolPriceIsLoading,
    isError: birdeyeSolPriceIsError,
  } = useQuery(["birdeyeSolPrice"], async () => {
    const headers = {
      "X-API-KEY": "f7a4922cbf9042929359723468c2bed7",
    };

    const res = await axios.get(
      "https://public-api.birdeye.so/defi/price?address=So11111111111111111111111111111111111111112",
      { headers }
    );
    return res.data;
  });

  if (birdeyeSolPriceIsError) {
    /**
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Error Warning</h5>

          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <i className="bi bi-exclamation-octagon me-1"></i>
            We're sorry - there was an error processing your request.
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <p>
            This error is usually caused by sending too many requests within one
            minute.
          </p>
          <p>Please wait a moment before refreshing the page again. Thanks!</p>
        </div>
      </div>
    );
    */
    return (
      <div>
        <section className="section">
          <div className="row align-items-top">
            <div className="col-lg-8">
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-12">
                    <div className="card mb-9" style={{ padding: "2rem" }}>
                      <center>
                        <h1>
                          <b>King's Royal Fund</b>
                        </h1>
                        <h4></h4>
                      </center>
                    </div>
                    <div className="card-body">
                      <center>
                        <p className="card-text" style={{ fontSize: "small" }}>
                          King's Royal Fund is the first of its kind—a unique
                          hypothetical fund. It is a diversified portfolio that
                          includes NFTs, cryptocurrencies, stocks, mutual funds,
                          and other SPL tokens. This imaginative fund is
                          intricately linked to the Pixel Apes collection and is
                          managed by one of Solana's most experienced, wise, and
                          trusted leaders: Ape King of Pixel Apes. With a track
                          record of over 30 years demonstrating exceptional
                          business and financial acumen in real life (IRL),
                          coupled with a rich history in overseeing one of
                          Solana's longest-standing collections, Pixel Apes,
                          this fund is poised to draw the attention of a wide
                          spectrum of investors, from 'degens' to the most
                          risk-averse in today's market. It's important to note
                          that all of this is purely hypothetical.
                          <br />
                        </p>
                        <p className="card-text" style={{ fontSize: "small" }}>
                          <a
                            href="/assets/pdf/PixelApes_Investment_Fund_Whitepaper_Final_Version_052824.pdf"
                            target="_blank"
                          >
                            PixelApes_Investment_Fund_Whitepaper_Final_Version_052824.pdf
                          </a>
                          <br />
                        </p>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 d-flex align-items-stretch p-0">
              <div className="card mb-3 w-100">
                <div className="row g-0">
                  <div className="col-md-4">
                  <br />
                    <img height='230' src="https://alternative.me/crypto/fear-and-greed-index.png" alt="Latest Crypto Fear & Greed Index" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="row align-items-top" style={{ padding: "1rem" }}>
            <div className="col-lg-12">
              <div className="card mb-12" style={{ padding: "1rem" }}>
                <iframe
                  src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQFXgCt89ysBHYmrqzmvAmFcu9neX_k8EwGWDd1ntr24zrrkzFM2czeF_-v0gq2bhp9B-gha_Fd5ews/pubhtml?gid=576368981&amp;widget=false&amp;headers=false&amp;chrome=false"
                  width="100%"
                  height="920"
                ></iframe>
                <iframe
                  src="https://docs.google.com/spreadsheets/d/e/2PACX-1vR2Ztzy_fkPllSVg1gLzg10zGfQO_0M074QjkNGOjpEhW9mGWn_jO-cysRFkgIoL4sKQ3svNesMONks/pubhtml?gid=0&amp;single=true&amp;widget=false&amp;headers=false&amp;chrome=false"
                  width="100%"
                  height="110"
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        <br />
      </div>
    );
  }

  if (birdeyeSolPriceIsLoading) {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Loading Data...</h5>

          <div
            className="alert alert-primary alert-dismissible fade show"
            role="alert"
          >
            <i className="bi bi-star me-1"></i>
            Thanks for your patience—loading King's Royal Fund data!
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <section className="section">
          <div className="row align-items-top">
            <div className="col-lg-8">
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-12">
                    <div className="card mb-9" style={{ padding: "2rem" }}>
                      <center>
                        <h1>
                          <b>King's Royal Fund</b>
                        </h1>
                        <h4></h4>
                      </center>
                    </div>
                    <div className="card-body">
                      <center>
                        <p className="card-text" style={{ fontSize: "small" }}>
                          King's Royal Fund is the first of its kind—a unique
                          hypothetical fund. It is a diversified portfolio that
                          includes NFTs, cryptocurrencies, stocks, mutual funds,
                          and other SPL tokens. This imaginative fund is
                          intricately linked to the Pixel Apes collection and is
                          managed by one of Solana's most experienced, wise, and
                          trusted leaders: Ape King of Pixel Apes. With a track
                          record of over 30 years demonstrating exceptional
                          business and financial acumen in real life (IRL),
                          coupled with a rich history in overseeing one of
                          Solana's longest-standing collections, Pixel Apes,
                          this fund is poised to draw the attention of a wide
                          spectrum of investors, from 'degens' to the most
                          risk-averse in today's market. It's important to note
                          that all of this is purely hypothetical.
                          <br />
                        </p>
                        <p className="card-text" style={{ fontSize: "small" }}>
                          <a
                            href="/assets/pdf/PixelApes_Investment_Fund_Whitepaper_Final_Version_052824.pdf"
                            target="_blank"
                          >
                            PixelApes_Investment_Fund_Whitepaper_Final_Version_052824.pdf
                          </a>
                          <br />
                        </p>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 d-flex align-items-stretch p-0">
              <div className="card mb-3 w-100">
                <div className="row g-0">
                  <div className="col-md-4">
                  <br />
                    <img height='230' src="https://alternative.me/crypto/fear-and-greed-index.png" alt="Latest Crypto Fear & Greed Index" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h4 className="solPriceHeader">
                        <br />
                        <br />
                        <br />
                        Price of SOL:{" "}
                        <b>${1 * birdeyeSolPriceData.data.value.toFixed(2)}</b>
                      </h4>
                      <br />
                      <p
                        className="card-text"
                        style={{ fontSize: "small", textAlign: "right" }}
                      >
                        <i>Live price data from BirdEye.so</i>
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="row align-items-top" style={{ padding: "1rem" }}>
            <div className="col-lg-12">
              <div className="card mb-12" style={{ padding: "1rem" }}>
                <iframe
                  src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQFXgCt89ysBHYmrqzmvAmFcu9neX_k8EwGWDd1ntr24zrrkzFM2czeF_-v0gq2bhp9B-gha_Fd5ews/pubhtml?gid=576368981&amp;widget=false&amp;headers=false&amp;chrome=false"
                  width="100%"
                  height="920"
                ></iframe>
                <iframe
                  src="https://docs.google.com/spreadsheets/d/e/2PACX-1vR2Ztzy_fkPllSVg1gLzg10zGfQO_0M074QjkNGOjpEhW9mGWn_jO-cysRFkgIoL4sKQ3svNesMONks/pubhtml?gid=0&amp;single=true&amp;widget=false&amp;headers=false&amp;chrome=false"
                  width="100%"
                  height="110"
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        <br />
      </div>
    );
  }
};

export default KingsNFTFund;
